import Dashboard from './DashboardAdmin'
// import Table from './Table'
// import Login from './Login'
// import Forbidden from './Forbidden'
// import Pdf from './PDFView'

export const components = {
    Dashboard,
    // Table,
    // Login,
    // Forbidden,
    // Pdf
}

