
import { Layout,Menu, 
  // Breadcrumb,Button 
} from 'antd';
import { useState } from 'react';
import {
    // DesktopOutlined,
    PieChartOutlined,
    // PoweroffOutlined
    // FileOutlined,
    // TeamOutlined,
    // UserOutlined,
  } from '@ant-design/icons';
  import Tabel from './Table'
  import Form from './Form'
 
  import { Route } from "react-router-dom";
  // import axios from "axios"
  // import UbahDom from "../utils/UbahDom"
  // import {PDFViewer} from '@react-pdf/renderer'
// const { SubMenu } = Menu;
const { Header, Footer, Sider, Content } = Layout;

export default function DashboardAdmin(props) {
    
    const [collapsed,setCollapsed] = useState(false);
    // const [collapsed,setCollapsed] = useState(false);
    //   onCollapse = collapsed => {
    //     console.log(collapsed);
    //     setCollapsed({ collapsed });
    //   };
    
      
        // const { collapsed } = this.state;
        // const logout=()=>{
        //   const token = localStorage.getItem("token")
        //   let headers = {
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Authorization": token,
        //     },
        //     responseType: "json"
        //   };      
        //   axios
        //     .post(`/auth/logout`, null, headers)
        //     .then(res => {
                 
        //      console.log(res);
              
        //     })
        //     .catch((error)=> {
              
        //     console.log(error.status);
        //     });
        //     localStorage.removeItem("token")
        //     localStorage.removeItem("level")
        //     UbahDom(`/`,props.history)

        // }
        const handleClick = (e) => {
          // console.log("e",e)
          // console.log("this",props)
          
          const { history } = props;
          history.push("/admin");
          switch (e.key) {
            case "1": {
              history.push("/");
              return;
            }
           
           
            default:
              return "foo";
          }
        };
      
        // const level = localStorage.getItem("level")
        return (
          <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={()=>setCollapsed(!collapsed)}>
              <div className="logo">
                {/* <img src={logo} alt="logo uin" height="32px"/> */}
              </div>
             
              <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" onClick={handleClick}>
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                  Pengacara
                </Menu.Item>
                
                
              </Menu>
             
            {/* <div >
              <Menu style={{position: "absolute",bottom: "50px"}} theme="dark" mode="inline" onClick={logout}>
                <Menu.Item key="99" icon={<PoweroffOutlined />}>
                Keluar
                </Menu.Item>
                
              </Menu>
            </div> */}
            {/* <Button
              type="primary"
              // theme="dark"
              icon={<PoweroffOutlined />}
              // onClick={() => this.logout}
              ghost block
            >
              Keluar
            </Button> */}
            </Sider>
            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }} />
              <Content style={{ margin: '0 16px' }}>
                {/* <Breadcrumb style={{ margin: '16px 0' }}>
                  <Breadcrumb.Item>User</Breadcrumb.Item>
                  <Breadcrumb.Item>Bill</Breadcrumb.Item>
                </Breadcrumb> */}
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                 
                 <Route exact path="/" component={Tabel}></Route>
                <Route exact path="/TambahPengacara" component={Form}></Route>
                {/*<Route exact path="/admin/Form" component={FormJudul}></Route>
                <Route exact path="/admin/pdf" component={PDFView}></Route>
                <Route exact path="/admin/proposal" component={Proposal}></Route>
                <Route exact path="/admin/Formproposal" component={FormJudul}></Route>
                <Route exact path="/admin/Formproposal/:id" component={FormJudul}></Route>
                <Route exact path="/admin/hasil" component={Proposal}></Route>
                <Route exact path="/admin/Formhasil" component={FormJudul}></Route>
                <Route exact path="/admin/Formhasil/:id" component={FormJudul}></Route>
                <Route exact path="/admin/tutup" component={Proposal}></Route>
                <Route exact path="/admin/Formtutup" component={FormJudul}></Route>
                <Route exact path="/admin/Formtutup/:id" component={FormJudul}></Route>
                <Route exact path="/admin/Forbidden" component={Forbidden}></Route> */}
           
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
          </Layout>
        );
      }

