import React from 'react'
import { Form, Input, Button,Upload,Col,Row,Checkbox } from 'antd';
import {
    UploadOutlined
  } from '@ant-design/icons';
import axios from 'axios'
// import UbahDom from '../utils/UbahDom'
// import moment from 'moment'
// import FormatDate from '../utils/FormatDate'


class FormPengacara extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:[],
      file: []
    } 
    this.uploadFile = this.uploadFile.bind(this)
  }

 
  onFinish = (values) => {
    
        // console.log(this.props)
        // const v =values
        // v.image = this.state.file
        const formdata = new FormData()
        formdata.append("nama",values.nama)
        formdata.append("bidang",values.bidang)
        formdata.append("tahun_kerja",values.tahun_kerja)
        formdata.append("lulusan",values.lulusan)
        formdata.append("kantor",values.kantor)
        formdata.append("deskripsi",values.deskripsi)
        formdata.append('image',this.state.file)
        // v.image = values.upload
        // console.log("v : ",formdata);
    //    const v = values
    //    v.waktu = moment(v.waktu).format('YYYY-MM-DD')
    //    console.log('ss', v);
    //     if(this.props.match.params.id){
    //       // console.log("update");
          // axios.put(`${this.state.endPointTo}/${this.props.match.params.id}`, v).then(res => {
    //         UbahDom(this.state.redirect,this.props.history)
    //       });
          
    //     }else{
          // console.log(this.props);
        axios.post("/master/pengacara", formdata).then(res => {
          // console.log(res)
   this.props.history.push("/")

        })
        .catch(err=>{
          console.log(err);
        });
    //   }
      };
    onchange(props){
      // console.log(props);
    }
     normFile = (e) => {
        // console.log('Upload event:', e);
      
        if (Array.isArray(e)) {
          return e;
        }
      
        return e && e.fileList;
      };
      uploadFile(e){
        console.log(e);
        // this.setState({file:e})
        this.setState({file:e})
      }
  render(){
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 12,
      },
    };
    const {data} = this.state
    return(
      <Form {...layout} name="nest-messages" onFinish={this.onFinish}
      fields={data}
        >
      <Form.Item
        name={'nama'}
        label="Nama"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item 
        name="bidang" 
        label="Bidang"
        rules={[
            {
              required: true,
            },
          ]}>
        <Checkbox.Group>
          <Row>
            <Col span={8}>
              <Checkbox value="Perbankan" style={{ lineHeight: '32px' }}>
              Perbankan
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Keluarga" style={{ lineHeight: '32px' }}>
              Keluarga
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Sengketa tanah" style={{ lineHeight: '32px' }}>
              Sengketa tanah
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Developer" style={{ lineHeight: '32px' }}>
              Developer
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Utang Piutang" style={{ lineHeight: '32px' }}>
              Utang Piutang
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Tenaga Kerja dan Profesi" style={{ lineHeight: '32px' }}>
              Tenaga Kerja dan Profesi
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Usaha dan Bisnis" style={{ lineHeight: '32px' }}>
              Usaha dan Bisnis
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Teknologi Informasi" style={{ lineHeight: '32px' }}>
              Teknologi Informasi
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Kriminal / Kejahatan Pidana" style={{ lineHeight: '32px' }}>
              Kriminal / Kejahatan Pidana
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox value="Hak Kekayaan atas Intelektual" style={{ lineHeight: '32px' }}>
              Hak Kekayaan atas Intelektual
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name={'tahun_kerja'}
        label="Tahun Kerja"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'lulusan'}
        label="Lulusan"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'kantor'}
        label="Kantor"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="upload"
        label="Upload"
        valuePropName="fileList"
        getValueFromEvent={this.normFile}
        extra="Foto Pengacara"
      >
        <Upload name="logo" action={this.uploadFile} listType="picture">
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={'deskripsi'}
        label="Deskripsi"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
     
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    )
  }


}
export default FormPengacara