import React from 'react';
import { Table, Input, Button, Space,Popconfirm} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PlusOutlined,DeleteFilled,EditFilled } from '@ant-design/icons';
import axios from "axios";
import {Link} from 'react-router-dom'
// import FormatDate from '../utils/FormatDate'

class Tabel extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      searchText: '',
      searchedColumn: '',
      data:[]
    };
    this.handleDelete = this.handleDelete.bind(this)

  }
  
 

  async componentDidMount() {
    // console.log(this.props);
    this.getData()
  }
  getData(){
    let headers = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: localStorage.getItem("token")
      },
      responseType: "json"
    };
    axios.get("/master/pengacara", headers).then(res => {
      this.setState({ diagnostics: res.data.diagnostic });
      if (this.state.diagnostics.status === 200) {
        let index = 0;
        let datas = [];
        for (const qd of res.data.result) {
          const data = {}
          const ind = Object.keys(qd)
          for (let i = 0; i <= ind.length; i++) {
            if (i === ind.length)
              data.key = index
            else if (ind[i] === "foto")
              data[ind[i]] = `http://api.hukumdigital.com/image/${qd[ind[i]]}` 
            else
              data[ind[i]] = qd[ind[i]]
          }
          index++
          datas.push(data)
        }
        // console.log(datas);
        this.setState({
          data: datas
        });
      }
    });
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleDelete(e){
    console.log(e);
    const headers = {
      'Access-Control-Allow-Origin':'*',
      "Access-Control-Expose-Headers": "Content-Length, X-JSON",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "*"
    }
    // console.log(headers);
    axios.delete(`/master/pengacara/${e}`,{headers:headers}).then((res)=>{
      console.log(res);
      this.getData()
    }).catch((err)=>{
      console.log(err);
    })
  }

  render() {
    // console.log(this.props);
    const columns = [
        {
            title: 'ID Pengacara',
            dataIndex: 'id',
            key: 'id',
            ...this.getColumnSearchProps('id'),
          },
        {
            title: 'Nama',
            dataIndex: 'nama',
            key: 'nama',
            ...this.getColumnSearchProps('nama'),
          },
        {
            title: 'Bidang',
            dataIndex: 'bidang',
            key: 'bidang',
            ...this.getColumnSearchProps('bidang'),
          },
        {
            title: 'Tahun Kerja',
            dataIndex: 'tahun_kerja',
            key: 'tahun_kerja',
            ...this.getColumnSearchProps('tahun_kerja'),
          },
        {
            title: 'Lulusan',
            dataIndex: 'lulusan',
            key: 'lulusan',
            ...this.getColumnSearchProps('lulusan'),
          },
        {
            title: 'Kantor',
            dataIndex: 'kantor',
            key: 'kantor',
            ...this.getColumnSearchProps('kantor'),
          },
        {
            title: 'Foto',
            dataIndex: 'foto',
            key: 'foto',
            render: (text, record) => {
            return <img height="100px" width="100px" src={text} alt={record.nama} />},
        },
        {
            title: 'Deskripsi',
            dataIndex: 'deskripsi',
            key: 'deskripsi',
            ...this.getColumnSearchProps('deskripsi'),
          },
          {
          title: 'Aksi',
          dataIndex: 'aksi',
          key: 'aksi',
          render: (text, record) => (<><Popconfirm title="Anda Ingin menghapus Data Pengacara？" okText="Ya" cancelText="Tidak" onConfirm={()=>this.handleDelete(record.id)}>
          <DeleteFilled/>
        </Popconfirm><Link  to="#" ><EditFilled/></Link></>),
        }
    ]
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button type="primary"
            onClick={() =>this.props.history.push("/TambahPengacara")}
            icon={<PlusOutlined />}>
            Tabmah
          </Button>

        </div>
        <Table columns={columns} dataSource={this.state.data} />
      </div>
    );
  }
}
export default Tabel;